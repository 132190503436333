<template>
  <c-step :items="stepItems" v-model="step"></c-step>
</template>

<script>
export default {
  name: "equipment-inspection-detail",
  props: {
    popupParam: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      step: 2,
      stepItems: [
        // {
        //   name: 1,
        //   title: '점검계획',
        //   icone: '',
        //   disable: false,
        //   message: '※  설비 추가 후 저장하세요. </font>',
        //   component: () => import(`${'./equipmentPlan.vue'}`),
        //   param: {
        //     a: 1,
        //     b: 'c'
        //   }
        // },
        {
          name: 2,
          title: '점검결과',
          icone: '',
          disable: false,
          message: '※  실비점검 결과 목록 행을 클릭하세요.</font>',
          component: () => import(`${'./equipmentResult.vue'}`),
          param: {
            a: 2,
            b: 'c'
          }
        },
        {
          name: 3,
          title: '완료',
          icone: '',
          disable: false,
          component: () => import(`${'./equipmentComplete.vue'}`),
          param: {
            a: 3,
            b: 'c'
          }
        },
      ],
      editable: true,
      detailUrl: "",
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {},
  },
};
</script>
